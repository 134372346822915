<div class="rs-card">
  <div class="head">
    <div class="title with-spinner" appTestID="specific-robot-commands-title">Commands</div>
    <div class="right-buttons">
      <button mat-flat-button class="left-icon-button rs-inline-button" color="primary" (click)="onAddNewCommand()">
        <span class="ri-add"></span>
        <span>Add Command</span>
      </button>
      <app-documentation-button link="commands"></app-documentation-button>
    </div>
  </div>

  <app-loading-content [isLoading]="isLoading">
    <div class="body p-0">
      <div>
        <table mat-table matSort [dataSource]="dataSource">
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Id </mat-header-cell>
            <mat-cell *matCellDef="let item">
              <div class="id">{{ item.value.id }}</div>
            </mat-cell>
          </ng-container>

          <!-- Command Column -->
          <ng-container matColumnDef="command">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Command</mat-header-cell>
            <mat-cell *matCellDef="let item">
              {{ item?.value | commandType }}
            </mat-cell>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Description </mat-header-cell>
            <mat-cell *matCellDef="let item">
              <div class="description">{{ item?.value?.name }}</div>
            </mat-cell>
          </ng-container>

          <!-- Parameters Column -->
          <ng-container matColumnDef="parameters">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Parameters </mat-header-cell>
            <mat-cell *matCellDef="let item">
              {{ item?.value?.parameters | commandParameters }}
            </mat-cell>
          </ng-container>

          <!-- Edit Column -->
          <ng-container matColumnDef="editControls">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let item">
              <div (click)="$event.stopPropagation()" class="row-buttons">
                <ng-container *ngIf="item?._isOverridden || (!item?._isOverridden && currentModule !== 'robot')">
                  <button mat-icon-button (click)="onEditCommand(item)" matTooltip="Edit" aria-label="Edit">
                    <span class="ri-edit"></span>
                  </button>
                  <button mat-icon-button (click)="onRemoveCommand(item)" matTooltip="Remove" aria-label="Remove">
                    <span class="ri-delete"></span>
                  </button>
                </ng-container>
                <ng-container *ngIf="currentModule === 'robot' && !item?._isOverridden">
                  <div matTooltip="Defined in Profile">
                    <span class="rs-grey-icon rs-icon-button-padding ri-robot-definition"></span>
                  </div>
                </ng-container>
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns" class="hover-row"></mat-row>
        </table>
      </div>
    </div>
  </app-loading-content>
</div>
