<div class="loading-content-wrapper" [class.is-loading]="isLoading" [class.full-height]="fullHeight">
  <div class="spinner-overlayer" [class.full-height]="fullHeight" *ngIf="isLoading">
    <div class="center-spinner">
      <app-loading-spinner [isLoading]="isLoading" size="x3" theme="light-grey"></app-loading-spinner>
    </div>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
